import './src/theme/destyle.css'
import './src/theme/global.css'

import { ThemeProvider } from './src/theme'
import { Transition } from './src/utils/transition'

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider>
      <Transition {...props}>{element}</Transition>
    </ThemeProvider>
  )
}
const transitionDelay = 500

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)

    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay,
    )
  }
  return false
}
